<template>
  <div class="economic-index">
       
      <span class="text-h5 text--primary d-flex pl-5 font-weight-bold"> 
        글로벌 경제 지표 
      </span>
      <span class="text-caption d-flex pl-5 pt-5">
          출처: 미국 세인트루이스 연방준비은행, 한국은행
      </span>
      
      <v-card outlined>
      <v-row>
        <!-- 지표 구분 선택 -->      
        <v-col cols="12" sm="6" md="3" lg="3">
          <v-radio-group
            class="d-flex pa-5"
            v-model="selectedType"
            row
            @change="get_type"
          >
            <v-radio
              label="주요 경제지표"
              value="economicIndex"
            ></v-radio>
            <v-radio
              label="경기 선행지표"
              value="LeadingEconomicIndex"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3">
          <!-- 경제지표 선택 -->
          <v-select
              class="d-flex pa-5"
              :items="indexes"
              label="경제지표"
              item-text="text"
              item-value="key"
              v-model="selectedIndex"
              @change="get_economic_index"
              single-line
              hint="경제지표를 선택하세요."
              persistent-hint
          ></v-select>
        </v-col>
      </v-row>
      </v-card>
      <br>
      <v-row 
          align="center"
          justify="end"
      >
         <p class="text-caption d-flex mr-16">
              <v-btn text @click="drawChart(1)">1Y</v-btn>  
              <v-btn text @click="drawChart(5)">5Y</v-btn>  
              <v-btn text @click="drawChart(10)">10Y</v-btn>  
              <v-btn text @click="drawChart(99)">MAX</v-btn>
          </p>
      </v-row>

      <!-- Progress Line -->
      <v-container v-if="progressShow" style="height: 400px;" >
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          데이터 조회중입니다.
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>

    <v-card outlined >
      <!-- 미국 경기선행지표 -->    
      <v-container fluid v-if="usLEIShow">      
        <v-row align="center">
          <v-col :cols="cols1">
            <p class="text-h5 text--primary d-flex pa-2">
              {{title}}<br>
            </p>
            <p class="text-caption d-flex pa-2">
                {{ unit }}
            </p>
            <div class="text--primary d-flex pa-2 justify-start text-justify">
              {{ description }}
            </div>          
          </v-col>
          <v-col :cols="cols2">        
            <a class="text-h5" target="_blank" href="https://www.conference-board.org/topics/us-leading-indicators">
              컨퍼런스보드 사이트 가기
            </a>     
          </v-col>
        </v-row>          
      </v-container>

    <v-container fluid v-else>
    <v-row>
        <v-col cols="12" sm="3" md="3" lg="3" xl="4">
          <v-row v-if="chartShow" >
            <span class="text-h5 text--primary d-flex pl-5 pt-5">
              {{title}}
            </span>
          </v-row>
          <v-row v-if="chartShow" >
            <span class="text-caption d-flex pl-6 pb-5 justify-start">
              {{ unit }}
            </span>
          </v-row>          
          <v-row v-if="chartShow">
            <v-list-item
              v-for="(item, i) in updownItems"
              :key="i"
              dense
              class="text-h6 text--primary d-flex justify-start"
            >
              <v-list-item-content>
                <v-row justify="start" no-gutters>
                <v-col cols="6" > {{ item.country }}: </v-col> 
                <v-col cols="1" :class="fontColor[i]"> {{ item.updown }} </v-col> 
                <v-col cols="5" :class="fontColor[i]"> {{ item.lastval }} </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-row>
          <v-row v-if="chartShow" class="text--primary d-flex pa-5 text-justify">
            {{ description }}
          </v-row>          
        </v-col>
        <v-col cols="12" sm="9" md="9" lg="9" xl="8">        
          <line-chart
            v-if="chartShow"
            :chartData="datacollection"
            :options="chart_options"
            :height="200" 
          />    
        </v-col>       
      </v-row>   
    </v-container>
    </v-card>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import {mapState, mapActions, mapMutations} from 'vuex';
  import LineChart from '../components/LineChart.js';
  
  export default {
      name: "EconomicIndex",
      components: { LineChart },
      data() {
          return {
            progressShow: false,
            chartShow: false,
            usLEIShow: false,
            HTMLcontent: null,
            selectedIndex: 0,
            selectedType: 'economicIndex',
            majors: [{key: 0, text: '경제성장율(GDP)'}, 
                      {key: 1, text: '소비자물가지수(CPI)'}, 
                      {key: 2, text: '가계부채비율'}, 
                      {key: 3, text: '10년물 국채금리'}, 
                      {key: 4, text: '실업률'}, 
                      {key: 5, text: '브라질 경제성장율'}, 
                      {key: 6, text: '한국 수출증가율'}, 
                      {key: 7, text: '원달러환율'}
                      ],
            leis: [{key: 8, text: '미국 경기선행지수'}, 
                    {key: 9, text: '한국 선행지수 순환변동치'}, 
                    {key: 10, text: '기준금리'}, 
                    {key: 11, text: '통화량증가율(유동성)'},
                    {key: 12, text: '미국 장단기금리스프레드'}, 
                    {key: 13, text: '미국 주택가격임대료비율'} 
                  ],
            frequency: {
              0:'Q', 
              1:'M', 
              2:'Q', 
              3:'D', 
              4:'M', 
              5:'D', 
              6:'Q', 
              7:'M',
              8:'M',
              9:'D',
              10:'D',
              11:'M',
              12:'D',
              13:'Q',
            },
            units: {
              0: '(주기: 분기, 단위: 전년동기비)',
              1: '(주기: 매월, 단위: 전년동월비)',
              2: '(주기: 분기, 단위: GDP=100)',
              3: '(주기: 매일(미국), 매월(한국), 단위: %)',
              4: '(주기: 매월, 단위: %)',
              5: '(주기: 분기, 단위: 전년 동기비)',
              6: '(주기: 매월, 단위: 전년 동기비)',
              7: '(주기: 매일, 단위: 미화 1달러당 한화)',
              8: '(주기: 매월 넷째수, 단위: 전월비)',
              9: '(주기: 매월, 단위: 2015Y=100)',
              10: '(주기: 일, 단위: %)',
              11: '(주기: 주/월, 단위: 전년동기비)',
              12: '(주기: 매일, 단위: %)',
              13: '(주기: 분기, 단위: %)',
            },
            descriptions: {
              0: 'GDP는 일정 기간 국내에서 생산된 재화와 서비스의 시장가치의 합계로 민간소비, 투자, 정부지출 및 순수출 합계로 계산되며 경제성장율은 실질 GDP의 증감률로 표현됨',
              1: '소비자물가지수는 가정이 소비하기 위해 구입하는 재화와 서비스의 평균 가격을 측정한 지수로 인플레이션 선행지표로 중앙은행의 금리 조정 바로미터임',
              2: 'GDP대비 가계부채비율로 일반적으로 소비자 대출 및 부동산 담보대출로 측정, 가계부채의 누적은 차입가계의 원리금 상환부담 가중 및 가계의 소비제약 등을 통해 경제성장에 부정적인 영향을 줌',
              3: '10년물 국채금리(장기금리)는 모든 자산가격의 벤치마크로 장기금리에 영향을 주는 요인은 성장, 기대인플레이션, 불확실성임 상당폭의 기준금리 인상은 성장둔하 및 경기침체 유발로 안전자산인 장기국채 수요증가로 장기국채 금리하락 하방 압력으로 장단기금리차 축소 및 역전현상 발생으로 경기침체의 선행지표임',
              4: '실업률은 실업자수를 경제활동인구의 백분율로 고용지표 중 하나로 경제상황을 평가하는 바로미터임. 일반적으로 3~4%의 실업률을 완전 고용 상태라고 봄, 실업율이 높으면 기준금리를 인하하여 경기부양책을 펼치며, 낮으면 고금리 기조로 전환',
              5: '브라질은 전세계 원자재 수출의 대리인 역할',
              6: '기술 분야에서 아시아의 주요 수출국 중 하나로 글로벌 IT 수요에 대한 통찰력 제공',
              7: '원/달러환율은 여러 대내외 요인의 영향을 받는데, 이는 국제수지, 외국인 증권매수자금 등 외환수급동향, 미 달러화 등의 주요국 환율동향 등에 의해 결정됨',
              8: '경제연구기관인 컨퍼런스보드에서 매월 넷째주 발표. 전월대비 3개월 연속 하락하면 경제가 침체에 빠질 신호라고 여겨지고 있음. 구성 지표는 1)장단기금리차, 2)통화량 증가율, 3)제조업 주간 노동시간, 4) 신규수주, 5)S&P 주가지수, 6)신규실업수당청구건수, 7)ISM 물품인도지 수, 8)주택건축 허가건수, 9)소비자기대지수, 10)비국방자본재 수주',
              9: '통계청에서 매월말 발표되는 산업활동동향 자료에서 선행지 수 전년동월비를 발표하고 있음. 기계 및 건설 수주액, 소비자 기대지수, 주가, 장 단기금리차등 경기 순환에 앞서서 나타나는10개의 구성지표로 종합되어 있음',
              10: '금융기관과 환매조건부증권(RP) 매매, 자금조정 예금 및 대출 등의 거래를 할 때 기준*이 되는 정책금리로서 간단히 ‘기준금리’(base rate)라고도 함. 한·미간 기준금리 역전시 원·달러 환율은 상승',
              11: 'M2는 현금과 요구불, 수시입출금식 예금에 2년 미만 정기 예·적금 같은 단기 금융상품까지 포괄하는 넓은 의미의 통화지표',
              12: '장단기 금리 역전은 주요 금융시장 및 경제지표 중에서 경기침체에 대한 예측력이 가장 정확한 지표 중 하나임. 단기 금리가 장기 금리보다 높은 비정상적인 상황이 지속되면 투자나 소비가 위축돼 경제 활동이 둔화할 가능성이 커지기 때문임.',
              13: '임대료보다 주택가격이 폭등하면 임대수요가 늘어나고 자가주택 수요가 감소함에 따라 주택 시장이 위축되어 경제 전체에 부정적인 영향을 미칠 수 있음. 역대 경기침체 전 이 지표가 먼저 하향함으로서 경기침체 선행지표임'
            },
            chart_ticks: {
              0:{},
              1:{},
              2:{ min: 60, max: 120, stepSize: 10, reverse: false, beginAtZero: true },
              3:{},
              4:{},
              5:{},
              6:{ min: 1000, max: 1400, stepSize: 100, reverse: false, beginAtZero: true },            
              7:{},
              8:{},
              9:{},
              10:{},
              11:{},
              12:{},
              13:{ min: 1.0, max: 2.0, stepSize: 0.1, reverse: false, beginAtZero: true }
            },
            indexes: [],            
            resData: null,
            updownItems: null,
            title: null,
            unit: null,
            description: null,

            datacollection: null,
            chart_options: null,    
            
            fontColor: [],
           
        }
      },
      
      created() {     
        this.indexes = this.majors;   
        if (this.isLogin) this.get_economic_index();
        else this.$router.push("/");
      },
      
      computed: {
          ...mapState(['debug', 'isLogin', 'user', 'who']), 
        cols1 () {
          const { xs } = this.$vuetify.breakpoint
          return xs ? 0 : 3
        },
        cols2 () {
          const { xs } = this.$vuetify.breakpoint
          return xs ? 12 : 9
        },
      },
  
      methods: {

        get_type() {
          if (this.selectedType === 'economicIndex') {
            this.indexes = this.majors;
          } else if (this.selectedType === 'LeadingEconomicIndex') {
            this.indexes = this.leis;
          }
        },
          
        get_economic_index() {  
          this.chartShow = false;
              
          if (this.selectedIndex === 8) { // 미국경기선행지수
              this.usLEIShow = true;
              this.indexes.forEach(val => {
                if (val['key'] === this.selectedIndex) this.title = val['text'];
              });
              this.unit = this.units[this.selectedIndex];
              this.description = this.descriptions[this.selectedIndex]; 
              return
          }          
          this.progressShow = true;
          this.usLEIShow = false; 
          const quaryData = { 'user': this.user, 'index': this.selectedIndex };            
          var vm = this;    
          const jwt = localStorage.getItem('jwt');
          const headers = {"Authorization": "jwt "+ jwt};
                
          axios.post('/api/economy/index/list/', quaryData, {headers})
            .then(function(res) {
              vm.progressShow = false;
              vm.resData = res.data;
              vm.drawChart(99);                                      
              vm.chartShow = true; 
              })
              .catch(function (err) {
                vm.snackbar = true;
                vm.snackColor = 'error';
                vm.snackText = err;    
              });  
        },
          
        setChartOptions(ticksOption) {
          const chartOptions = 
            {
              title: {
                display: false,
                text: '경제지표',
              },
              scales: {
                xAxes: [{
                  gridLines: { drawOnChartArea: false },
                  ticks: {fontSize: 10}
                }],
                yAxes: [{
                  gridLines: { drawOnChartArea: true },
                  ticks: ticksOption
                }]
              },
              legend: {
                display: true,
              },                     
            };
          return chartOptions;
        },
  
        getLastValue(values) {
          var nonNullList = [];
          values.forEach(val => {
            if (val != null) nonNullList.push(val);
          });
          const revValues = nonNullList.reverse();  // 배열 뒤집기
          return revValues[0];
        },
  
        checkUpDown(values) {
          var nonNullList = [];
          values.forEach(val => {
            if (val != null) nonNullList.push(val);
          });
          const revValues = nonNullList.reverse();  // 배열 뒤집기
          
          if (Number(revValues[1]) > Number(revValues[0])) {
            return {'icon': '▼', 'color': 'd-flex red--text'}          
          }
          else if (Number(revValues[1]) < Number(revValues[0])) {
            return {'icon': '▲', 'color': 'd-flex blue--text'}         
          }
          else 
            return {'icon': ' ', 'color': 'd-flex black--text'} 
        },
  
        // 미국 경제 침체기 바차트 
        setResessionBar(frequency, valList, labelList) {
          const resessionList = [];
          var maxValList = [];
          valList.forEach(val => {
            maxValList.push(Math.max(...val));
          });       
          // 바차트 높이를 최대값 기준으로 설정
          const maxVal = Math.max(...maxValList);
  
          // 침체구간 설정
          labelList.forEach(val => { 
            if (frequency == 'D') 
                if (
                  (val >= '19481201' && val <= '19491031') ||
                  (val >= '19530701' && val <= '19540531') ||
                  (val >= '19570801' && val <= '19580430') ||
                  (val >= '19600401' && val <= '19610228') ||
                  (val >= '19691201' && val <= '19701130') ||
                  (val >= '19731101' && val <= '19750531') ||
                  (val >= '19800101' && val <= '19800731') ||
                  (val >= '19810701' && val <= '19821130') ||
                  (val >= '19900701' && val <= '19910531') ||
                  (val >= '20010501' && val <= '20011130') ||
                  (val >= '20071201' && val <= '20090630') ||
                  (val >= '20200201' && val <= '20200430')
                ) 
                  resessionList.push(maxVal);  
                else 
                  resessionList.push(0);          
            else if (frequency == 'M') 
                if (
                  (val >= '194812' && val <= '194910') ||
                  (val >= '195307' && val <= '195405') ||
                  (val >= '195708' && val <= '195804') ||
                  (val >= '196004' && val <= '196102') ||
                  (val >= '196912' && val <= '197011') ||
                  (val >= '197311' && val <= '197505') ||
                  (val >= '198001' && val <= '198007') ||
                  (val >= '198107' && val <= '198211') ||
                  (val >= '199007' && val <= '199105') ||
                  (val >= '200105' && val <= '200111') ||
                  (val >= '200712' && val <= '200906') ||
                  (val >= '202002' && val <= '202004')
                ) 
                  resessionList.push(maxVal);  
                else 
                  resessionList.push(0);
            else if (frequency == 'Q') 
                if (
                  (val >= '1948Q4' && val <= '1949Q4') ||
                  (val >= '1953Q3' && val <= '1954Q2') ||
                  (val >= '1957Q3' && val <= '1958Q2') ||
                  (val >= '1960Q2' && val <= '1961Q1') ||
                  (val >= '1970Q1' && val <= '1970Q4') ||
                  (val >= '1973Q4' && val <= '1975Q2') ||
                  (val >= '1980Q1' && val <= '1980Q3') ||
                  (val >= '1981Q3' && val <= '1982Q4') ||
                  (val >= '1990Q3' && val <= '1991Q2') ||
                  (val >= '2001Q2' && val <= '2001Q4') ||
                  (val >= '2008Q1' && val <= '2009Q3') ||
                  (val == '2020Q2')
                ) 
                  resessionList.push(maxVal);  
                else 
                  resessionList.push(0);                    
          });
          
          return resessionList;
        },
  
  
        getRange(frequency, scope, maxLength) {
          var _range = 0;
          if (frequency == 'D') {
              if (scope == 1) _range = -366;
              else if (scope == 5) _range = -365*5+1;
              else if (scope == 10) _range = -365*10+1;
              else if (scope == 99) _range = maxLength * -1;
          } 
          else if (frequency == 'M') {
              if (scope == 1) _range = -13;
              else if (scope == 5) _range = -61;
              else if (scope == 10) _range = -121;
              else if (scope == 99) _range = maxLength * -1;
          } 
          else if (frequency == 'Q') {
              if (scope == 1) _range = -5;
              else if (scope == 5) _range = -21;
              else if (scope == 10) _range = -41;
              else if (scope == 99) _range = maxLength * -1;
          } 
  
          return _range;
        },
  
  
        convertNanToNull(data) {
          var convertedData = []
          data.forEach(val => {
            val = val === 9999 ? null : val;
            convertedData.push(val);
          });
          return convertedData;
        },
  
  
        setDatasets(valList, resessionVal, countries) {
          const colores = ['orange', 'blue', 'green'];
          const datasets = [];
          for (var i = 0; i < valList.length; i++) {
            datasets.push({ type: 'line', data: valList[i], label: countries[i], borderColor: colores[i], 
              backgroundColor: colores[i], fill: false, radius: 0, borderWidth: 1});
          }
          if (this.selectedIndex != 9) {
            datasets.push({ type: 'bar', data: resessionVal, label: '미국 경제침체기', borderColor: '#EEEEEE', 
              backgroundColor: '#EEEEEE', fill: true, radius: 0, borderWidth: 1});
          }
          return datasets
        },        
        
        drawChart(scope) {        
          
          this.indexes.forEach(val => {
            if (val['key'] === this.selectedIndex) this.title = val['text'];
          });

          var range = 0;     
          range = this.getRange(this.frequency[this.selectedIndex], scope, this.resData['indexes'].length);   
          
          const values = [];
          this.resData['values'].forEach(val => {
            values.push(val.slice(range));
          });  
          
          // 9999 to null로 변환 (소스데이터 None 값을 9999로 변한하여 응답함)
          const convertedValues = []
          values.forEach(val => {
            convertedValues.push(this.convertNanToNull(val)); 
          });

          const lastValues = [];
          const upDownes = [];
          this.fontColor = [];
          convertedValues.forEach(val => {
            lastValues.push(this.getLastValue(val));
            const updown = this.checkUpDown(val); 
            upDownes.push(updown['icon']);
            this.fontColor.push(updown['color'])
          });

          this.updownItems = []
          for (var i = 0; i < upDownes.length; i++) {
            this.updownItems.push({'country': this.resData['countries'][i], 'updown': upDownes[i], 'lastval': lastValues[i]+'%'})
          }

          this.unit = this.units[this.selectedIndex];
          this.description = this.descriptions[this.selectedIndex]; 
          const labels = this.resData['indexes'].slice(range);            
          const resession = this.setResessionBar(this.frequency[this.selectedIndex], convertedValues, labels);
          const v_datasets = this.setDatasets(convertedValues, resession, this.resData['countries']);
          this.datacollection = { labels: labels, datasets: v_datasets };  
          this.chart_options = this.setChartOptions(this.chart_ticks[this.selectedIndex]);   
        }        
      },      
  }
  </script>
  
  <style>
  .economic-index{
     font-family: 'Source Sans Pro', 'Noto Sans KR script=latin rev=3';
     margin-top: 10px;
  }
  .red {
    color: red;
  }
  .stackSheet {
    position: relative;
  }
  .stackSpark {
    position: absolute;
    top: 0;
    left: 0;
  }
  </style>
  
  